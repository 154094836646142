var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"toggle":_vm.isOpen,"loading":_vm.loading,"styles":{
    modal: {
      minHeight: '70vh',
      maxHeight: '70vh'
    }
  },"amenities-reservation-requests-list":""},on:{"update:toggle":function($event){_vm.isOpen=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Reservation Requests List")]},proxy:true},{key:"header2",fn:function(){return [_c('b-field',{staticClass:"card",style:({ padding: '20px', overflow: 'visible' }),attrs:{"label":"Amenity Name"}},[_c('b-autocomplete',{attrs:{"amenity-name-autocomplete":"","data":_vm.amenityNames,"field":"value","open-on-focus":true,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); }},model:{value:(_vm.amenityFilter),callback:function ($$v) {_vm.amenityFilter=$$v},expression:"amenityFilter"}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{style:({ display: 'flex', justifyContent: 'end' })})]},proxy:true}])},[_c('section',_vm._l((_vm.filteredAmenities),function(r,i){return _c('div',{key:i,staticClass:"card",style:({
        padding: '8px',
        marginBottom: '8px',
        display: 'flex',
        justifyContent: 'space-between'
      })},[_c('div',[_c('div',[_vm._v("Amenity: "+_vm._s(r.amenityName))]),_c('div',[_vm._v("Amenity ID: "+_vm._s(r.amenityID))]),_c('div',[_vm._v("From: "+_vm._s(r.startDate))]),_c('div',[_vm._v("To: "+_vm._s(r.endDate))]),_c('div',[_vm._v("For: "+_vm._s(r.owner))])]),_c('div',[_c('b-button',{style:({ marginLeft: '24px' }),attrs:{"type":"is-primary","rounded":"","outlined":""},on:{"click":function($event){return _vm.confirmDeny(r)}}},[_vm._v("Deny")]),_c('b-button',{style:({ marginLeft: '24px' }),attrs:{"type":"is-primary","rounded":"","outlined":""},on:{"click":function($event){return _vm.confirmApprove(r)}}},[_vm._v("Approve")])],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }