var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"amenities-adder":"","toggle":_vm.toggle,"loading":_vm.loading},on:{"update:toggle":function($event){_vm.toggle=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Create Amenity")]},proxy:true},{key:"footer",fn:function(){return [_c('div',{style:({ display: 'flex', justifyContent: 'end' })},[_c('b-button',{staticClass:"is-primary",attrs:{"amenity-adder-button":""},on:{"click":_vm.add}},[_vm._v("Add")])],1)]},proxy:true}])},[_c('valid-input',{attrs:{"amenity-name-input":"","label":"Name","name":"Name","placeholder":"Name","spellcheck":"true"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('br'),_c('valid-input',{attrs:{"amenity-description-textarea":"","type":"textarea","label":"Description","name":"Description","placeholder":"Description","spellcheck":"true"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('br'),_c('div',{style:(Object.assign({}, {display: 'flex',
      alignItems: 'center',
      minHeight: '50px'},
      (_vm.breakpoint === 'desktop'
        ? {}
        : {
            flexDirection: 'column',
            alignItems: 'flex-start'
          })))},[_c('b-checkbox',{attrs:{"amenity-time-limit-checkbox":""},model:{value:(_vm.hasTimeLimit),callback:function ($$v) {_vm.hasTimeLimit=$$v},expression:"hasTimeLimit"}},[_vm._v("Has Time Limit")]),_c('div',{style:(Object.assign({}, {display: 'flex',
        marginLeft: '20px',
        opacity: _vm.hasTimeLimit ? 1 : 0,
        transition: 'opacity .3s'},
        (_vm.breakpoint === 'desktop'
          ? {}
          : {
              paddingTop: '12px'
            })))},[_c('b-field',{attrs:{"label":"Hours"}},[_c('b-select',{attrs:{"amenity-time-limit-hours-select":"","placeholder":"Hours"},model:{value:(_vm.timeLimit.hours),callback:function ($$v) {_vm.$set(_vm.timeLimit, "hours", $$v)},expression:"timeLimit.hours"}},_vm._l((_vm.hours),function(h){return _c('option',{key:h,domProps:{"value":h}},[_vm._v(" "+_vm._s(h)+" ")])}),0)],1),_c('b-field',{attrs:{"label":"Minutes"}},[_c('b-select',{attrs:{"amenity-time-limit-minutes-select":"","placeholder":"Minutes"},model:{value:(_vm.timeLimit.minutes),callback:function ($$v) {_vm.$set(_vm.timeLimit, "minutes", $$v)},expression:"timeLimit.minutes"}},_vm._l((_vm.minutes),function(m){return _c('option',{key:m,domProps:{"value":m}},[_vm._v(" "+_vm._s(m)+" ")])}),0)],1)],1)],1),_c('br'),_c('div',{style:({ display: 'flex', alignItems: 'center', minHeight: '50px' })},[_c('b-field',[_c('b-checkbox',{attrs:{"amenity-approval-required-checkbox":""},model:{value:(_vm.requiresApproval),callback:function ($$v) {_vm.requiresApproval=$$v},expression:"requiresApproval"}},[_vm._v("Requires Approval")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }