import _get from 'lodash/get'
import { approveAmenityReservation } from '@/services/Amenities/AmenityReservation/ApproveReservation'
import { listAmenities } from '@/services/Amenities/Amenity/List'
import { deleteAmenityReservation } from '@/services/Amenities/AmenityReservation/Delete'
import { notifyMessage, notifyProblem } from '@/services/notify'
import { amenityStore } from '@/services/Amenities/store'

export const methods = {
  open({ amenities, amenityName }) {
    this.isOpen = true
    this.showing = 1

    if (typeof amenityName === 'string') {
      this.amenityFilter = amenityName
    }

    this.amenities = amenities
  },

  async refresh() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { list, successful, message } = await listAmenities({
      params: {
        hoaID
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    this.amenities = list
  },

  convenienceFeeFromPercent(amount, percent) {
    if (amount && percent) {
      try {
        const percentage = percent * 0.01
        const percentAmount = amount * percentage
        return percentAmount
      } catch (error) {
        console.debug('error in convenienceFeeFromPercent=' + error)
        return 0
      }
    }
  },

  async confirmApprove(reservation) {
    this.loading = true

    const { successful, message } = await approveAmenityReservation({
      amenityReservationID: reservation.amenityReservationID
    })
    if (!successful) {
      notifyProblem(message)
      return
    } else {
      console.debug('reservation=' + JSON.stringify(reservation.reservation.chargeAmount))

      if (
        reservation !== undefined &&
        reservation.reservation !== undefined &&
        reservation &&
        reservation.reservation
      ) {
        //Will also need to compute this on every refund
        const chargedAmount =
          reservation.reservation.chargeAmount +
          reservation.reservation.nonRefundableAmount +
          this.convenienceFeeFromPercent(
            reservation.reservation.chargeAmount + reservation.reservation.nonRefundableAmount,
            reservation.reservation.convenienceFeeAmount || 0
          )

        var formatter = new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2
        })

        notifyMessage(
          `The home owner's credit card was charged, $` +
            `${formatter.format(
              chargedAmount || 0
            )}. The home owner 'Billing Account Activity' section will show when the payment is processed.`
        )
      }
    }

    await amenityStore.commit('updateMain')

    notifyMessage(`The reservation has been approved`)

    await this.refresh()

    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })

    this.loading = false

    // this.isOpen = false;
  },

  async confirmDeny(reservation) {
    this.loading = true

    const { successful, message } = await deleteAmenityReservation({
      amenityReservationID: reservation.amenityReservationID,
      params: {
        isBeingRejectedByManager: true
      }
    })
    if (!successful) {
      console.error(message)
      return
    }

    await amenityStore.commit('updateMain')
    notifyMessage(`The reservation request has been denied.`)

    await this.refresh()

    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })

    this.loading = false
  }
}
