var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContent',{style:({ paddingBottom: '300px' }),attrs:{"title":_vm.$t('amenities.title'),"amenities-main-page":""}},[_c('section',{style:(Object.assign({}, {display: 'flex'},
      (_vm.breakpoint === 'desktop'
        ? {}
        : {
            flexDirection: 'column'
          })))},[(_vm.authUserType === 'Account')?_c('b-button',{attrs:{"amenity-adder-modal-opener":"","type":"is-primary","rounded":""},on:{"click":_vm.openAddModal}},[_vm._v("Add Amenity")]):_vm._e(),(_vm.authUserType === 'Account')?_c('b-button',{style:(Object.assign({}, (_vm.breakpoint === 'desktop'
          ? {
              marginLeft: '12px'
            }
          : {
              marginTop: '12px'
            }))),attrs:{"type":"is-primary","rounded":"","disabled":_vm.futureRRs.length === 0},on:{"click":function($event){return _vm.openReservationRequestsModal({ amenities: _vm.list })}}},[_vm._v(_vm._s(_vm.futureRRs.length)+" Reservation Requests")]):_vm._e()],1),(_vm.list !== undefined || _vm.list)?_c('section',{style:({ margin: '20px 0' }),attrs:{"tests-id":"amenities-main-page"}},[_c('div',{staticClass:"is-12 level-right"},[_c('span',[_vm._v("Sort")]),(_vm.sortDirection === 'asc')?_c('span',[_c('img',{attrs:{"src":require("@/assets/icons/downarrow.svg")},on:{"click":function($event){$event.preventDefault();return _vm.processSort.apply(null, arguments)}}})]):_c('span',[_c('img',{attrs:{"src":require("@/assets/icons/uparrow.svg")},on:{"click":function($event){$event.preventDefault();return _vm.processSort.apply(null, arguments)}}})])]),_vm._l((_vm.list),function(amenity,index){return _c('div',{key:index,staticClass:"card",style:({ marginBottom: '12px', padding: '12px' }),attrs:{"amenity-card":""}},[_c('div',{style:({ display: 'flex', justifyContent: 'space-between' })},[_c('h4',{style:({ margin: 0 })},[_vm._v(_vm._s(amenity.name))]),_c('div',{style:({ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' })},[(
              typeof amenity.reservationDurationLimitInMinutes === 'number' &&
                amenity.reservationDurationLimitInMinutes > 0
            )?_c('b-taglist',{style:({ margin: 0 }),attrs:{"attached":""}},[_c('b-tag',{style:({ borderRight: '1px solid #DDD' }),attrs:{"type":"is-light"}},[_vm._v("Reservation Time Limit")]),_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v(_vm._s(Math.round(amenity.reservationDurationLimitInMinutes / 60) + ' hour(s)'))])],1):_vm._e(),(_vm._get(amenity, 'requiresApproval', null) === true)?_c('b-taglist',{style:({ marginLeft: '12px' }),attrs:{"attached":""}},[_c('b-tag',{style:({ borderRight: '1px solid #DDD' }),attrs:{"type":"is-light"}},[_vm._v("Reservations Require Approval")])],1):_vm._e()],1)]),_c('p',{style:({ padding: '12px 0' })},[_vm._v(_vm._s(amenity.description))]),_c('div',{style:(Object.assign({}, {display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end'},
          (_vm.breakpoint === 'desktop'
            ? {}
            : {
                flexDirection: 'column',
                alignItems: 'unset'
              })))},[(_vm.portal === 1)?_c('div',{staticClass:"card",style:(Object.assign({}, {padding: '12px',
            justifyContent: 'space-between'},
            (_vm.breakpoint === 'desktop'
              ? {}
              : {
                  marginBottom: '12px'
                }))),attrs:{"home-owner-reservations-total":""}},[_c('div',{style:({ textAlign: 'center', paddingBottom: '8px' })},[_vm._v(" Your Current/Future Events ")]),_c('div',[_c('b-button',{staticClass:"button is-info is-light is-small is-outline is-rounded",attrs:{"size":"is-small"},on:{"click":function($event){_vm.showReservations({
                  reservations: _vm.getArray(
                    _vm.your,
                    [_vm._get(amenity, 'amenityID', ''), 'future', 'reservations', 'list'],
                    []
                  ),
                  amenityName: _vm._get(amenity, 'name', '')
                })}}},[_vm._v(_vm._s(_vm._get( _vm.your, [_vm._get(amenity, 'amenityID', ''), 'future', 'reservations', 'count'], '__' ))+" "+_vm._s(_vm._get(amenity, 'requiresApproval', '') >= 1 ? 'Approved ' : '')+"Reservation"+_vm._s(_vm.getNumber( _vm.your, [_vm._get(amenity, 'amenityID', ''), 'future', 'reservations', 'count'], '__' ) === 1 ? '' : 's')+" ")]),(_vm._get(amenity, 'requiresApproval', null) === true)?_c('b-button',{staticClass:"button is-info is-light is-small is-outline is-rounded",style:({ marginLeft: '12px' }),attrs:{"size":"is-small"},on:{"click":function($event){_vm.showReservationRequests({
                  reservations: _vm.getArray(
                    _vm.your,
                    [_vm._get(amenity, 'amenityID', ''), 'future', 'requests', 'list'],
                    []
                  ),
                  amenityName: _vm._get(amenity, 'name', '')
                })}}},[_vm._v(_vm._s(_vm._get(_vm.your, [_vm._get(amenity, 'amenityID', ''), 'future', 'requests', 'count'], '__'))+" Pending Reservation Request"+_vm._s(_vm.getNumber( _vm.your, [_vm._get(amenity, 'amenityID', ''), 'future', 'requests', 'count'], '__' ) === 1 ? '' : 's')+" ")]):_vm._e()],1)]):_vm._e(),(_vm.portal === 2)?_c('div',{staticClass:"card",style:(Object.assign({}, {padding: '12px',
            justifyContent: 'space-between'},
            (_vm.breakpoint === 'desktop'
              ? {}
              : {
                  marginBottom: '12px'
                }))),attrs:{"totals-buttons":""}},[_c('div',{style:({ textAlign: 'center', paddingBottom: '8px' })},[_vm._v("Current/Future Events")]),_c('div',[_c('b-button',{staticClass:"button is-info is-light is-small is-outline is-rounded",attrs:{"size":"is-small"},on:{"click":function($event){_vm.showReservations({
                  reservations: _vm.getArray(
                    _vm.totals,
                    [_vm._get(amenity, 'amenityID', ''), 'future', 'reservations', 'list'],
                    []
                  ),
                  amenityName: _vm._get(amenity, 'name', '')
                })}}},[_vm._v(_vm._s(_vm._get( _vm.totals, [_vm._get(amenity, 'amenityID', ''), 'future', 'reservations', 'count'], '__' ))+" "+_vm._s(_vm._get(amenity, 'requiresApproval', '') >= 1 ? 'Approved ' : '')+"Reservation"+_vm._s(_vm.getNumber( _vm.totals, [_vm._get(amenity, 'amenityID', ''), 'future', 'reservations', 'count'], '__' ) === 1 ? '' : 's')+" ")]),(_vm._get(amenity, 'requiresApproval', null) === true)?_c('b-button',{staticClass:"button is-info is-light is-small is-outline is-rounded",style:({ marginLeft: '12px' }),attrs:{"size":"is-small"},on:{"click":function($event){_vm.showAllAmenityReservationRequests({
                  reservations: _vm.getArray(
                    _vm.totals,
                    [_vm._get(amenity, 'amenityID', ''), 'future', 'requests', 'list'],
                    []
                  ),
                  amenityName: _vm._get(amenity, 'name', '')
                })}}},[_vm._v(_vm._s(_vm._get( _vm.totals, [_vm._get(amenity, 'amenityID', ''), 'future', 'requests', 'count'], '__' ))+" Pending Reservation Request"+_vm._s(_vm.getNumber( _vm.totals, [_vm._get(amenity, 'amenityID', ''), 'future', 'requests', 'count'], '__' ) === 1 ? '' : 's')+" ")]):_vm._e()],1)]):_vm._e(),_c('span',[_c('router-link',{staticClass:"button is-info is-light is-small is-outline is-rounded",style:({
              margin: '6px'
            }),attrs:{"to":("/amenity/" + (amenity.amenityID))}},[_vm._v("Calendar")]),_c('router-link',{staticClass:"button is-info is-light is-small is-outline is-rounded",style:({
              margin: '6px'
            }),attrs:{"to":{
              name: 'newconversation',
              params: {
                amenityid: amenity.amenityID,
                amenityownerid: _vm.ownerID,
                amenitylabel: ("Add Homeowner - " + (_vm.ownerName || ''))
              }
            }}},[_vm._v("Ask a Question")]),_c('router-link',{staticClass:"button is-info is-light is-small is-outline is-rounded",style:({
              margin: '6px'
            }),attrs:{"to":{
              name: 'amenityconversations',
              params: {
                id: amenity.amenityID
              }
            }}},[_vm._v("Conversations")]),(_vm.authUser.userType != 'HomeOwner')?_c('b-button',{staticClass:"button is-info is-light is-small is-outline is-rounded",style:({
              margin: '6px'
            }),attrs:{"amenity-updater-modal-opener":""},on:{"click":function($event){return _vm.openUpdateModal(amenity)}}},[_vm._v("Edit")]):_vm._e(),_c('b-button',{staticClass:"button is-info is-light is-small is-outline is-rounded",style:({
              visibility: _vm.authUserType === 'Account' ? '' : 'hidden',
              margin: '6px'
            }),on:{"click":function($event){return _vm.eraseAmenity({ name: amenity.name, amenityID: amenity.amenityID })}}},[_vm._v("Delete")])],1)])])})],2):_vm._e(),_c('section',[_c('div',[_vm._v(" No Amenties Listed ")])]),_c('Adder',{ref:"adder"}),_c('Updater',{ref:"updater",attrs:{"toggle":_vm.toggleUpdater,"amenity":_vm.selectedAmenity}}),_c('ReservationRequestsList',{ref:"rrModal"}),_c('ReservationsList',{ref:"resList"}),_c('AmenityDeletionModal',{ref:"deletionModal"}),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }