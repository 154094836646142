<template>
  <Modal
    amenities-deletion-modal
    :toggle.sync="isOpen"
    :loading="loading"
    :styles="{ _modal: { maxWidth: '1100px' } }"
  >
    <template v-slot:header>
      <div>Amenity Deletion</div>
    </template>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'end' }">
        <b-input v-model="nameInput" amenity-name-input />
        <b-button
          confirm-delete-button
          type="is-danger"
          :style="{ marginLeft: '12px' }"
          @click="deleteAmenity"
          :disabled="
            typeof amenityName === 'string' && amenityName.length >= 1 && amenityName === nameInput
              ? false
              : true
          "
          >Delete</b-button
        >
      </div>
    </template>

    <p>{{ message }}</p>
    <p :style="{ marginTop: '12px' }">
      To proceed, please write the name of the amenity in the input box next to the delete button
      and then press the delete button.
    </p>
  </Modal>
</template>

<script>
/*
	import AmenityDeletionModal from '@/pages/Management/amenities/DeletionModal'

  <AmenityDeletionModal ref="deletionModal" />
*/

import Modal from '@/components/Modal'

export default {
  components: {
    Modal
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      message: '',
      amenityName: null,
      nameInput: '',
      onConfirm() {}
    }
  },
  methods: {
    async deleteAmenity() {
      const amenityName = this.amenityName
      const nameInput = this.nameInput

      if (typeof amenityName === 'string' && amenityName.length >= 1 && amenityName === nameInput) {
        await this.onConfirm()

        this.isOpen = false
        this.message = ''
        this.amenityName = ''
        this.loading = false

        this.onConfirm = () => {}

        return
      }
    },
    open({ message, amenityName, onConfirm }) {
      this.message = message
      this.isOpen = true
      this.loading = false

      this.amenityName = amenityName
      this.onConfirm = onConfirm
    }
  }
}
</script>
