import { amenityStore } from '@/services/Amenities/store'

export function mounted() {
  this.refresh({
    showLoading: true
  })

  this.unsubAmenityStore = amenityStore.subscribe(mutation => {
    const mType = mutation.type

    if (['amenityAdded', 'amenityUpdated', 'updateMain'].includes(mType)) {
      this.refresh({
        showLoading: true
      })
    }
  })
}
