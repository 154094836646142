<template>
  <Modal :toggle.sync="isOpen" :loading="loading" amenities-reservations-list>
    <template v-slot:header v-if="showingRequests">Pending Reservation Requests List</template>
    <template v-slot:header v-else>Reservations List</template>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'end' }" v-if="showing === 2">
        <b-button type="is-danger" @click="cancel" rounded>Cancel Reservation</b-button>
      </div>
    </template>

    <div v-if="showing === 1">
      <div
        class="card"
        v-for="(r, i) in reservations"
        :key="i"
        :style="{
          padding: '8px',
          marginBottom: '8px',
          display: 'flex',
          justifyContent: 'space-between'
        }"
      >
        <div>
          <div>From: {{ r.startDate }}</div>
          <div>To: {{ r.endDate }}</div>
          <div>For: {{ r.reservation.identityInformation.name }}</div>
        </div>

        <b-button type="is-danger" @click="confirmCancel(r)" rounded>Cancel Reservation</b-button>
      </div>

      <div v-if="(!Array.isArray(reservations) || reservations.length === 0) && showingRequests">
        None
      </div>
      <div v-if="(!Array.isArray(reservations) || reservations.length === 0) && !showingRequests">
        None
      </div>
    </div>

    <div v-if="showing === 2">
      <p>Are you sure you would like to delete the reservation from:</p>
      <div v-if="!showingRequests">
        <p>
          Refundable Amount:
          {{ `${cancellation.reservation.reservation.chargeAmount || 0}` | currency }}
        </p>
        <p>
          Service Fee Amount (Non-Refundable):
          {{ `${cancellation.reservation.reservation.nonRefundableAmount || 0}` | currency }}
        </p>
        <p>
          Convenience Fee Amount (Non-Refundable):
          {{ `${convenienceFee || 0}` | currency }}
        </p>
      </div>
      <br />
      <p>{{ cancellation.reservation.startDate }} to {{ cancellation.reservation.endDate }}?</p>
      <br />
      <br />
    </div>
  </Modal>
</template>

<script>
/*
  import ReservationsList from '@/pages/Shared/amenities/ReservationsList'

  <ReservationsList ref="reservationsList" />

  this.$refs.reservationsList.open ({
    reservations: []
  })
*/

import { data } from './keys/data'
import { methods } from './keys/methods'
import Modal from '@/components/Modal'

export default {
  components: {
    Modal
  },
  data,
  methods
}
</script>
