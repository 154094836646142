<template>
  <Modal amenities-adder :toggle.sync="toggle" :loading="loading">
    <template v-slot:header>Create Amenity</template>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'end' }">
        <b-button amenity-adder-button class="is-primary" @click="add">Add</b-button>
      </div>
    </template>

    <valid-input
      amenity-name-input
      v-model="formData.name"
      label="Name"
      name="Name"
      placeholder="Name"
      spellcheck="true"
    />

    <br />

    <valid-input
      amenity-description-textarea
      v-model="formData.description"
      type="textarea"
      label="Description"
      name="Description"
      placeholder="Description"
      spellcheck="true"
    />

    <br />

    <div
      :style="{
        display: 'flex',
        alignItems: 'center',
        minHeight: '50px',
        ...(breakpoint === 'desktop'
          ? {}
          : {
              flexDirection: 'column',
              alignItems: 'flex-start'
            })
      }"
    >
      <b-checkbox amenity-time-limit-checkbox v-model="hasTimeLimit">Has Time Limit</b-checkbox>

      <div
        :style="{
          display: 'flex',
          marginLeft: '20px',
          opacity: hasTimeLimit ? 1 : 0,
          transition: 'opacity .3s',
          ...(breakpoint === 'desktop'
            ? {}
            : {
                paddingTop: '12px'
              })
        }"
      >
        <b-field label="Hours">
          <b-select amenity-time-limit-hours-select placeholder="Hours" v-model="timeLimit.hours">
            <option v-for="h in hours" :value="h" :key="h">
              {{ h }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Minutes">
          <b-select
            amenity-time-limit-minutes-select
            placeholder="Minutes"
            v-model="timeLimit.minutes"
          >
            <option v-for="m in minutes" :value="m" :key="m">
              {{ m }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <br />

    <div :style="{ display: 'flex', alignItems: 'center', minHeight: '50px' }">
      <b-field>
        <b-checkbox amenity-approval-required-checkbox v-model="requiresApproval"
          >Requires Approval</b-checkbox
        >
      </b-field>
    </div>
  </Modal>
</template>

<script>
/*
  

*/

import Modal from '@/components/Modal'

import ValidInput from '@/components/inputs/ValidInput'

import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    Modal,
    ValidInput
  },
  props: {
    toggle: Boolean
  },
  computed,
  data,
  methods
}
</script>
