import { mapGetters } from 'vuex'
import { mapState } from 'vuex'

export const computed = {
  ...mapGetters('user', ['authUser', 'currentHoaId', 'currentOwnerId']),
  ...mapState({
    breakpoint: state => state.win.breakpoint
  }),
  portal() {
    if (this.authUserType === 'HomeOwner') {
      return 1
    }
    if (this.authUserType === 'Account') {
      return 2
    }

    return null
  }
}
