<template>
  <PageContent
    :title="$t('amenities.title')"
    :style="{ paddingBottom: '300px' }"
    amenities-main-page
  >
    <section
      :style="{
        display: 'flex',
        ...(breakpoint === 'desktop'
          ? {}
          : {
              flexDirection: 'column'
            })
      }"
    >
      <b-button
        v-if="authUserType === 'Account'"
        amenity-adder-modal-opener
        type="is-primary"
        @click="openAddModal"
        rounded
        >Add Amenity</b-button
      >

      <b-button
        :style="{
          ...(breakpoint === 'desktop'
            ? {
                marginLeft: '12px'
              }
            : {
                marginTop: '12px'
              })
        }"
        v-if="authUserType === 'Account'"
        type="is-primary"
        @click="openReservationRequestsModal({ amenities: list })"
        rounded
        :disabled="futureRRs.length === 0"
        >{{ futureRRs.length }} Reservation Requests</b-button
      >
    </section>

    <section
      v-if="list !== undefined || list"
      :style="{ margin: '20px 0' }"
      tests-id="amenities-main-page"
    >
      <div class="is-12 level-right">
        <span>Sort</span>
        <span v-if="sortDirection === 'asc'"
          ><img @click.prevent="processSort" src="@/assets/icons/downarrow.svg"
        /></span>
        <span v-else><img @click.prevent="processSort" src="@/assets/icons/uparrow.svg"/></span>
      </div>
      <div
        amenity-card
        v-for="(amenity, index) in list"
        :key="index"
        class="card"
        :style="{ marginBottom: '12px', padding: '12px' }"
      >
        <div :style="{ display: 'flex', justifyContent: 'space-between' }">
          <h4 :style="{ margin: 0 }">{{ amenity.name }}</h4>
          <div
            :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }"
          >
            <b-taglist
              :style="{ margin: 0 }"
              attached
              v-if="
                typeof amenity.reservationDurationLimitInMinutes === 'number' &&
                  amenity.reservationDurationLimitInMinutes > 0
              "
            >
              <b-tag type="is-light" :style="{ borderRight: '1px solid #DDD' }"
                >Reservation Time Limit</b-tag
              >
              <b-tag type="is-light">{{
                Math.round(amenity.reservationDurationLimitInMinutes / 60) + ' hour(s)'
              }}</b-tag>
            </b-taglist>

            <b-taglist
              attached
              :style="{ marginLeft: '12px' }"
              v-if="_get(amenity, 'requiresApproval', null) === true"
            >
              <b-tag type="is-light" :style="{ borderRight: '1px solid #DDD' }"
                >Reservations Require Approval</b-tag
              >
            </b-taglist>
          </div>
        </div>

        <p :style="{ padding: '12px 0' }">{{ amenity.description }}</p>

        <div
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            ...(breakpoint === 'desktop'
              ? {}
              : {
                  flexDirection: 'column',
                  alignItems: 'unset'
                })
          }"
        >
          <div
            home-owner-reservations-total
            class="card"
            :style="{
              padding: '12px',
              justifyContent: 'space-between',
              ...(breakpoint === 'desktop'
                ? {}
                : {
                    marginBottom: '12px'
                  })
            }"
            v-if="portal === 1"
          >
            <div :style="{ textAlign: 'center', paddingBottom: '8px' }">
              Your Current/Future Events
            </div>
            <div>
              <b-button
                class="button is-info is-light is-small is-outline is-rounded"
                size="is-small"
                @click="
                  showReservations({
                    reservations: getArray(
                      your,
                      [_get(amenity, 'amenityID', ''), 'future', 'reservations', 'list'],
                      []
                    ),
                    amenityName: _get(amenity, 'name', '')
                  })
                "
                >{{
                  _get(
                    your,
                    [_get(amenity, 'amenityID', ''), 'future', 'reservations', 'count'],
                    '__'
                  )
                }}
                {{ _get(amenity, 'requiresApproval', '') >= 1 ? 'Approved ' : '' }}Reservation{{
                  getNumber(
                    your,
                    [_get(amenity, 'amenityID', ''), 'future', 'reservations', 'count'],
                    '__'
                  ) === 1
                    ? ''
                    : 's'
                }}
              </b-button>

              <b-button
                class="button is-info is-light is-small is-outline is-rounded"
                v-if="_get(amenity, 'requiresApproval', null) === true"
                :style="{ marginLeft: '12px' }"
                size="is-small"
                @click="
                  showReservationRequests({
                    reservations: getArray(
                      your,
                      [_get(amenity, 'amenityID', ''), 'future', 'requests', 'list'],
                      []
                    ),
                    amenityName: _get(amenity, 'name', '')
                  })
                "
                >{{
                  _get(your, [_get(amenity, 'amenityID', ''), 'future', 'requests', 'count'], '__')
                }}
                Pending Reservation Request{{
                  getNumber(
                    your,
                    [_get(amenity, 'amenityID', ''), 'future', 'requests', 'count'],
                    '__'
                  ) === 1
                    ? ''
                    : 's'
                }}
              </b-button>
            </div>
          </div>

          <div
            totals-buttons
            class="card"
            :style="{
              padding: '12px',
              justifyContent: 'space-between',
              ...(breakpoint === 'desktop'
                ? {}
                : {
                    marginBottom: '12px'
                  })
            }"
            v-if="portal === 2"
          >
            <div :style="{ textAlign: 'center', paddingBottom: '8px' }">Current/Future Events</div>
            <div>
              <b-button
                class="button is-info is-light is-small is-outline is-rounded"
                size="is-small"
                @click="
                  showReservations({
                    reservations: getArray(
                      totals,
                      [_get(amenity, 'amenityID', ''), 'future', 'reservations', 'list'],
                      []
                    ),
                    amenityName: _get(amenity, 'name', '')
                  })
                "
                >{{
                  _get(
                    totals,
                    [_get(amenity, 'amenityID', ''), 'future', 'reservations', 'count'],
                    '__'
                  )
                }}
                {{ _get(amenity, 'requiresApproval', '') >= 1 ? 'Approved ' : '' }}Reservation{{
                  getNumber(
                    totals,
                    [_get(amenity, 'amenityID', ''), 'future', 'reservations', 'count'],
                    '__'
                  ) === 1
                    ? ''
                    : 's'
                }}
              </b-button>

              <b-button
                class="button is-info is-light is-small is-outline is-rounded"
                v-if="_get(amenity, 'requiresApproval', null) === true"
                :style="{ marginLeft: '12px' }"
                size="is-small"
                @click="
                  showAllAmenityReservationRequests({
                    reservations: getArray(
                      totals,
                      [_get(amenity, 'amenityID', ''), 'future', 'requests', 'list'],
                      []
                    ),
                    amenityName: _get(amenity, 'name', '')
                  })
                "
                >{{
                  _get(
                    totals,
                    [_get(amenity, 'amenityID', ''), 'future', 'requests', 'count'],
                    '__'
                  )
                }}
                Pending Reservation Request{{
                  getNumber(
                    totals,
                    [_get(amenity, 'amenityID', ''), 'future', 'requests', 'count'],
                    '__'
                  ) === 1
                    ? ''
                    : 's'
                }}
              </b-button>
            </div>
          </div>

          <span>
            <router-link
              class="button is-info is-light is-small is-outline is-rounded"
              :to="`/amenity/${amenity.amenityID}`"
              :style="{
                margin: '6px'
              }"
              >Calendar</router-link
            >
            <router-link
              class="button is-info is-light is-small is-outline is-rounded"
              :to="{
                name: 'newconversation',
                params: {
                  amenityid: amenity.amenityID,
                  amenityownerid: ownerID,
                  amenitylabel: `Add Homeowner - ${ownerName || ''}`
                }
              }"
              :style="{
                margin: '6px'
              }"
              >Ask a Question</router-link
            >

            <router-link
              class="button is-info is-light is-small is-outline is-rounded"
              :to="{
                name: 'amenityconversations',
                params: {
                  id: amenity.amenityID
                }
              }"
              :style="{
                margin: '6px'
              }"
              >Conversations</router-link
            >

            <b-button
              v-if="authUser.userType != 'HomeOwner'"
              amenity-updater-modal-opener
              @click="openUpdateModal(amenity)"
              class="button is-info is-light is-small is-outline is-rounded"
              :style="{
                margin: '6px'
              }"
              >Edit</b-button
            >

            <b-button
              class="button is-info is-light is-small is-outline is-rounded"
              @click="eraseAmenity({ name: amenity.name, amenityID: amenity.amenityID })"
              :style="{
                visibility: authUserType === 'Account' ? '' : 'hidden',
                margin: '6px'
              }"
              >Delete</b-button
            >
          </span>
        </div>
      </div>
    </section>

    <section>
      <div>
        No Amenties Listed
      </div>
    </section>

    <Adder ref="adder" />
    <Updater ref="updater" :toggle="toggleUpdater" :amenity="selectedAmenity" />

    <ReservationRequestsList ref="rrModal" />
    <ReservationsList ref="resList" />
    <AmenityDeletionModal ref="deletionModal" />

    <b-loading :is-full-page="false" v-model="loading"></b-loading>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import Adder from '@/pages/Management/amenities/Adder'
import Updater from '@/pages/Management/amenities/Updater'
import ReservationsList from '@/pages/Shared/amenities/ReservationsList'
import ReservationRequestsList from '@/pages/Shared/amenities/ReservationRequestsList'
import AmenityDeletionModal from '@/pages/Management/amenities/DeletionModal'
import { beforeDestroy } from './keys/beforeDestroy'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'

export default {
  components: {
    Adder,
    Updater,
    AmenityDeletionModal,
    PageContent,
    ReservationsList,
    ReservationRequestsList
  },
  beforeDestroy,
  computed,
  data,
  methods,
  mounted,
  watch,
  i18n: {
    messages: {
      en: { amenities: { title: 'Amenities' } }
    }
  }
}
</script>
