/*
    import { getNumber } from '@/utilities/get/number'

    const num = getNumber ()
 */

import _get from 'lodash/get'

export function getNumber(a, b, c) {
  try {
    const variable = _get(a, b, c)
    if (typeof variable === 'number') {
      return variable
    }
  } catch (x) {
    console.error(x)
  }

  return NaN
}
