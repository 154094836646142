var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"amenities-updater":"","toggle":_vm.toggle,"loading":_vm.loading},on:{"update:toggle":function($event){_vm.toggle=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Edit Amenity")]},proxy:true},{key:"footer",fn:function(){return [_c('div',{style:({ display: 'flex', justifyContent: 'end' })},[_c('b-button',{staticClass:"is-primary",attrs:{"amenity-updater-button":""},on:{"click":_vm.update}},[_vm._v("Update")])],1)]},proxy:true}])},[_c('valid-input',{attrs:{"amenity-name-input":"","label":"Name","name":"Name","placeholder":"Name","spellcheck":"true"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('br'),_c('valid-input',{attrs:{"amenity-description-textarea":"","type":"textarea","label":"Description","name":"Description","placeholder":"Description","spellcheck":"true"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('br'),_c('div',{style:(Object.assign({}, {display: 'flex',
      alignItems: 'center',
      minHeight: '50px'},
      (_vm.breakpoint === 'desktop'
        ? {}
        : {
            flexDirection: 'column',
            alignItems: 'flex-start'
          })))}),_c('br'),_c('div',{style:({ display: 'flex', alignItems: 'center', minHeight: '50px' })},[_c('b-field',[_c('b-checkbox',{attrs:{"amenity-approval-required-checkbox":""},model:{value:(_vm.requiresApproval),callback:function ($$v) {_vm.requiresApproval=$$v},expression:"requiresApproval"}},[_vm._v("Requires Approval")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }