import _get from 'lodash/get'

export function data() {
  const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null)

  return {
    authUserType,
    loading: true,
    list: [],
    reservations: {},
    futureRRs: [],
    ownerID: 0,
    ownerName: '',
    sortDirection: 'asc',
    all: {
      pendingReservations: {},
      reservations: {}
    },

    totals: {},
    your: {},
    toggleUpdater: false,
    selectedAmenity: {}
  }
}
