import moment from 'moment'
import _get from 'lodash/get'
//
import { getFutureReservationRequests } from '@/utilities/amenities/getFutureReservationRequests'
//
export const computed = {
  amenityNames() {
    const amenities = this.amenities

    const { futureRRs, successful } = getFutureReservationRequests({
      amenities
    })
    if (!successful) {
      console.error('A problem occurred while determining future reservation requests.')
    }

    const list = []
    futureRRs.forEach(RR => {
      const name = _get(RR, ['__amenityDetails', 'name'], '')

      if (!list.includes(name)) {
        list.push(name)
      }
    })

    return list
  },
  filteredAmenities() {
    const amenities = this.amenities
    const amenityFilter = this.amenityFilter

    console.debug('filteredAmenities()...' + JSON.stringify(this.amenities))

    let { futureRRs, successful } = getFutureReservationRequests({
      amenities
    })
    if (!successful) {
      console.debug('A problem occurred while determining future reservation requests.')
      futureRRs = futureRRs === undefined || !futureRRs ? [] : futureRRs
    }

    return futureRRs
      .filter(r => {
        const amenityName = _get(r, ['__amenityDetails', 'name'], '')

        try {
          if (typeof amenityFilter !== 'string') {
            return true
          }

          if (amenityFilter.length === 0) {
            return true
          }

          if (amenityName.toUpperCase().indexOf(amenityFilter.toUpperCase()) >= 0) {
            return true
          }
        } catch (x) {
          console.error(x)
        }

        return false
      })
      .map(r => {
        return {
          amenityID: r.amenityID,
          amenityName: _get(r, ['__amenityDetails', 'name'], ''),
          amenityReservationID: _get(r, 'amenityReservationID', null),
          startDate: moment(r.startDate).format('LLLL'),
          endDate: moment(r.endDate).format('LLLL'),
          owner: _get(r, ['identityInformation', 'name'], ''),
          reservation: r
        }
      })
  }
}
