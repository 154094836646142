export function data() {
  return {
    isOpen: false,
    loading: false,
    amenities: null,
    reservations: [],
    amenityFilter: '',
    convenienceFee: 0
  }
}
