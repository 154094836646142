import { reservationIsApproved } from '@/utilities/amenities/reservationIsApproved'

import _get from 'lodash/get'

/*
	import { getFutureReservationRequests } from "@/utilities/amenities/getFutureReservationRequests"

	const { futureRRs, successful } = getFutureReservationRequests ({
		amenities: []
	})
*/

/*
	returns an array of reservations
		adds "__amenityDetails" key to reservations
*/

export function getFutureReservationRequests({ amenities }) {
  const futureRRs = []

  try {
    if (!Array.isArray(amenities)) {
      console.error(`getFutureReservationRequests necessitates that "amenities" is an array.`, {
        amenities: amenities === undefined || !amenities ? [] : amenities
      })

      return {
        successful: false,
        futureRRs: []
      }
    }

    for (let a = 0; a < amenities.length; a++) {
      const amenity = amenities[a]

      const requiresApproval = _get(amenity, 'requiresApproval', null)
      if (requiresApproval !== true) {
        continue
      }

      const amenityReservations = _get(amenity, 'amenityReservations', [])
      if (!Array.isArray(amenityReservations)) {
        continue
      }

      const reservations = amenityReservations
        .filter(reservation => {
          if (reservationIsApproved({ reservation })) {
            return false
          }

          return true
        })
        .map(reservation => {
          reservation.__amenityDetails = {
            name: _get(amenity, 'name')
          }

          return reservation
        })

      futureRRs.push(...reservations)
    }

    return {
      successful: true,
      futureRRs
    }
  } catch (x) {
    console.error(x)
  }

  return {
    successful: false,
    futureRRs: []
  }
}
