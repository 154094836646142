<template>
  <Modal amenities-updater :toggle.sync="toggle" :loading="loading">
    <template v-slot:header>Edit Amenity</template>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'end' }">
        <b-button amenity-updater-button class="is-primary" @click="update">Update</b-button>
      </div>
    </template>

    <valid-input
      amenity-name-input
      v-model="formData.name"
      label="Name"
      name="Name"
      placeholder="Name"
      spellcheck="true"
    />

    <br />

    <valid-input
      amenity-description-textarea
      v-model="formData.description"
      type="textarea"
      label="Description"
      name="Description"
      placeholder="Description"
      spellcheck="true"
    />

    <br />

    <div
      :style="{
        display: 'flex',
        alignItems: 'center',
        minHeight: '50px',
        ...(breakpoint === 'desktop'
          ? {}
          : {
              flexDirection: 'column',
              alignItems: 'flex-start'
            })
      }"
    ></div>

    <br />

    <div :style="{ display: 'flex', alignItems: 'center', minHeight: '50px' }">
      <b-field>
        <b-checkbox amenity-approval-required-checkbox v-model="requiresApproval"
          >Requires Approval</b-checkbox
        >
      </b-field>
    </div>
  </Modal>
</template>

<script>
/*
  

*/

import Modal from '@/components/Modal'

import ValidInput from '@/components/inputs/ValidInput'

import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    Modal,
    ValidInput
  },
  props: {
    toggle: Boolean,
    amenity: Object
  },
  mounted() {
    this.refresh()
  },
  computed,
  data,
  methods
}
</script>
