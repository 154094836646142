import _get from 'lodash/get'
import { amenityStore } from '@/services/Amenities/store'
import { notifyError, notifyMessage } from '@/services/notify'

export const methods = {
  async refresh(paramToggle, paramAmenity) {
    if (paramAmenity !== undefined && paramAmenity) {
      this.toggle = paramToggle
      this.amenity = paramAmenity
      this.isOpen = true
    }

    console.debug(paramToggle + ', in update modal refresh param...' + JSON.stringify(paramAmenity))

    if (this.amenity !== undefined && this.amenity) {
      this.formData.name = this.amenity.name
      this.formData.description = this.amenity.description
      this.requiresApproval = this.amenity.requiresApproval
    }
  },

  getPayload() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const payload = {
      amenityID: this.amenity.amenityID,
      hoaID,
      name: this.formData.name,
      description: this.formData.description,

      amenityReservations: [],
      amenityReservableTimes: [],

      canReserve: this.amenity.canReserve,
      publicNote: this.amenity.publicNote,
      internalNote: this.amenity.internalNote,

      refundableDeposit: this.amenity.refundableDeposit,
      nonRefundableDeposit: this.amenity.nonRefundableDeposit,
      usesReservableTime: this.amenity.usesReservableTime,
      createdDate: this.amenity.createdDate,

      timeBlocking: this.amenity.timeBlocking,

      requiresApproval: this.requiresApproval,
      reservationDurationLimitInMinutes: this.amenity.reservationDurationLimitInMinutes
    }

    return payload
  },

  async update() {
    this.loading = true

    if (this.amenity === undefined || !this.amenity) {
      notifyError('There was a problem loading the existing amenity.')
      return
    }

    // await amenityStore.dispatch('updateAmenity', {
    //   payload: this.getPayload(),
    //   done: async () => {
    //     await new Promise(resolve => {
    //       setTimeout(() => {
    //         this.loading = false
    //         this.isOpen = false
    //         this.toggle = false
    //         resolve()
    //       }, 1000)
    //     })
    //   }
    // })

    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const { successful, message } = await amenityStore.dispatch('updateAmenity', {
      json: {
        amenityID: this.amenity.amenityID,
        hoaID,
        name: this.formData.name,
        description: this.formData.description,

        amenityReservations: [],
        amenityReservableTimes: [],

        canReserve: this.amenity.canReserve,
        publicNote: this.amenity.publicNote,
        internalNote: this.amenity.internalNote,

        refundableDeposit: this.amenity.refundableDeposit,
        nonRefundableDeposit: this.amenity.nonRefundableDeposit,
        usesReservableTime: this.amenity.usesReservableTime,
        createdDate: this.amenity.createdDate,

        timeBlocking: this.amenity.timeBlocking,

        requiresApproval: this.requiresApproval,
        reservationDurationLimitInMinutes: this.amenity.reservationDurationLimitInMinutes
      }
    })
    if (!successful) {
      console.error(message)
      notifyError('There was a problem updating the amenity.')
      this.loading = false
      return
    }

    notifyMessage('The amenity was successfully updated.')

    await new Promise(resolve => {
      setTimeout(() => {
        this.loading = false
        this.isOpen = false
        this.toggle = false
        resolve()
      }, 1000)
    })
  },
  open() {
    this.isOpen = true
    this.loading = false
  }
}
