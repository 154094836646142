export function data() {
  return {
    isOpen: false,
    loading: false,
    reservations: [],
    showing: 1,
    cancellation: {},
    convenienceFee: 0,
    showingRequests: false
  }
}
