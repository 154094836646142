export const watch = {
  currentHoaId(hoaID) {
    console.debug(hoaID)
    this.refresh()
  },

  toggleUpdater() {
    if (this.toggleUpdater == false) {
      this.reload()
      this.toggleUpdater = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
    }
  }
}
