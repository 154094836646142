import _get from 'lodash/get'
import { getNumber } from '@/utilities/get/number'
import { listAmenities } from '@/services/Amenities/Amenity/List'
import { deleteAmenity } from '@/services/Amenities/Amenity/Delete'
import { notifyMessage, notifyProblem } from '@/services/notify'
import { getFutureReservationRequests } from '@/utilities/amenities/getFutureReservationRequests'
import { calcOwnerEventTotals } from '@/utilities/amenities/calculate/ownerEventTotals'
import { getArray } from '@/utilities/get/Array'
import { calcAllEventTotals } from '@/utilities/amenities/calculate/allEventTotals'
import Adder from '@/pages/Management/amenities/Adder'
import _orderBy from 'lodash/orderBy'
import { overlaysStore } from '@/components/Overlays/store'

export const methods = {
  _get,
  getNumber,
  getArray,

  async openAddModal() {
    await overlaysStore.dispatch('show', {
      component: Adder,
      props: {
        toggle: true
      }
    })
  },

  async openUpdateModal(amenity) {
    // await overlaysStore.dispatch('show', {
    //   component: Updater,
    //   props: {
    //     toggle: true,
    //     amenity: amenity
    //   }
    // })

    this.toggleUpdater = true
    this.selectedAmenity = amenity

    this.$refs.updater.refresh(true, amenity)
  },

  async openReservationRequestsModal({ amenities }) {
    this.$refs.rrModal.open({ amenities })
  },

  getFutureReservationRequestsCount({ amenities }) {
    const { futureRRs, successful } = getFutureReservationRequests({
      amenities
    })
    if (!successful) {
      console.error(`There was an error while getting future reservation requests.`)
    }

    this.futureRRs = futureRRs
  },

  async showReservations({ reservations, amenityName }) {
    this.$refs.resList.open({
      reservations,
      amenityName
    })
  },
  async showReservationRequests({ reservations, amenityName }) {
    this.$refs.resList.open({
      reservations,
      requests: true,
      amenityName
    })
  },

  /*

	*/
  async showAllAmenityReservationRequests({ amenityName }) {
    this.$refs.rrModal.open({
      amenities: this.list,
      amenityName
    })
  },

  async eraseAmenity({ name, amenityID }) {
    const component = this

    this.$refs.deletionModal.open({
      message: `Are you sure you want to delete '${name}'?`,
      amenityName: name,
      async onConfirm() {
        try {
          this.loading = true

          const { successful, message } = await deleteAmenity({
            amenityID
          })
          if (!successful) {
            notifyProblem(message)
            this.loading = false
            return
          }

          await component.refresh()

          notifyMessage(`${name} has been deleted successfully.`)
          component.loading = false
        } catch (x) {
          console.error(x)
        }
      }
    })
  },
  async determineReservations({ amenities }) {
    const reservationOwnerID = this.currentOwnerId
    this.ownerID = reservationOwnerID
    this.ownerName = _get(this, ['$store', 'getters', 'user/authUser', 'name'], null)

    for (let a = 0; a < amenities.length; a++) {
      const amenity = amenities[a]
      const id = _get(amenity, 'amenityID', null)
      if (typeof id !== 'number' || id < 1) {
        console.error(`The id of an amentity is not valid.`)
        console.error(amenity)
        continue
      }
      if (!Array.isArray(this.list[a].ownerReservations)) {
        this.list[a].ownerReservations = []
      }

      const reservations = _get(amenity, 'amenityReservations', '')

      for (let b = 0; b < reservations.length; b++) {
        const reservation = _get(reservations, b, {})
        const ownerID = _get(reservation, 'reservationOwnerID', null)

        if (reservationOwnerID === ownerID && typeof ownerID === 'number' && ownerID >= 1) {
          this.list[a].ownerReservations.push(reservation)
        }
      }
    }
  },

  //
  // 	Calculates future and current reservations
  //
  calcYourTotals({ amenities }) {
    const ownerID = this.currentOwnerId

    for (let a = 0; a < amenities.length; a++) {
      const amenity = amenities[a]
      const amenityID = _get(amenity, ['amenityID'], null)

      const { totals: your } = calcOwnerEventTotals({
        amenityData: amenity,
        ownerID
      })

      this.your[amenityID] = your
    }
  },

  calcAllTotals({ amenities }) {
    for (let a = 0; a < amenities.length; a++) {
      const amenity = amenities[a]
      const amenityID = _get(amenity, ['amenityID'], null)

      const { totals } = calcAllEventTotals({
        amenityData: amenity
      })

      this.totals[amenityID] = totals
    }
  },

  processSort() {
    if (this.list !== undefined && this.list) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
        this.list = _orderBy(this.list, 'createdDate', 'desc')
      } else {
        this.sortDirection = 'asc'
        this.list = _orderBy(this.list, 'createdDate', 'asc')
      }
    }
  },

  async refresh({ showLoading = false } = {}) {
    if (showLoading) {
      this.loading = true
    }

    const hoaID = this.currentHoaId
    const { list, successful, message } = await listAmenities({
      params: {
        hoaID
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    this.list = list
    this.getFutureReservationRequestsCount({
      amenities: list
    })

    this.calcYourTotals({
      amenities: list
    })
    this.calcAllTotals({
      amenities: list
    })

    if (this.authUserType === 'HomeOwner') {
      this.determineReservations({
        amenities: list
      })
    }

    if (showLoading) {
      this.loading = false
    }
  }
}
