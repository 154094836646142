import _get from 'lodash/get'
import { amenityStore } from '@/services/Amenities/store'

function getTimeLimit({ hours, minutes }) {
  if (typeof hours !== 'number' || hours < 0 || hours > 24) {
    return 0
  }

  if (typeof minutes !== 'number' || minutes < 0 || minutes > 60) {
    return 0
  }

  const total = hours * 60 + minutes

  if (total === 0) {
    return null
  }

  return total
}

export const methods = {
  async add() {
    this.loading = true

    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const timeLimit = getTimeLimit({
      hours: this.timeLimit.hours,
      minutes: this.timeLimit.minutes
    })

    const { successful, message } = await amenityStore.dispatch('addAmenity', {
      json: {
        hoaID,
        name: this.formData.name,
        description: this.formData.description,

        amenityReservations: [],
        amenityReservableTimes: [],

        canReserve: true,
        publicNote: '_',
        timeBlocking: '_',

        requiresApproval: this.requiresApproval,

        ...(typeof timeLimit === 'number'
          ? {
              reservationDurationLimitInMinutes: timeLimit
            }
          : {})
      }
    })
    if (!successful) {
      console.error(message)
      this.loading = false
      return
    }

    await new Promise(resolve => {
      setTimeout(() => {
        this.loading = false
        this.isOpen = false
        this.toggle = false
        resolve()
      }, 1000)
    })
  },
  open() {
    console.log('???')

    this.isOpen = true
    this.loading = false
  }
}
