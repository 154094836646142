/*
	import { deleteAmenity } from '@/services/Amenities/Amenity/Delete';

  const { successful, message } = await deleteAmenity ({
    amenityID,

  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function deleteAmenity({ amenityID }) {
  let message = 'A problem occurred and the amenity could not be deleted.'

  try {
    await kms.delete(`/Amenities/Amenity/${amenityID}?forceDelete=true`, {
      params: {
        forceDelete: true
      }
    })

    return {
      successful: true,
      message
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}
