<template>
  <Modal
    :toggle.sync="isOpen"
    :loading="loading"
    :styles="{
      modal: {
        minHeight: '70vh',
        maxHeight: '70vh'
      }
    }"
    amenities-reservation-requests-list
  >
    <template v-slot:header>Reservation Requests List</template>

    <template v-slot:header2>
      <b-field label="Amenity Name" class="card" :style="{ padding: '20px', overflow: 'visible' }">
        <b-autocomplete
          amenity-name-autocomplete
          v-model="amenityFilter"
          :data="amenityNames"
          field="value"
          @select="option => (selected = option)"
          :open-on-focus="true"
          :clearable="true"
        >
        </b-autocomplete>
      </b-field>
    </template>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'end' }"></div>
    </template>

    <section>
      <div
        class="card"
        v-for="(r, i) in filteredAmenities"
        :key="i"
        :style="{
          padding: '8px',
          marginBottom: '8px',
          display: 'flex',
          justifyContent: 'space-between'
        }"
      >
        <div>
          <div>Amenity: {{ r.amenityName }}</div>
          <div>Amenity ID: {{ r.amenityID }}</div>

          <div>From: {{ r.startDate }}</div>
          <div>To: {{ r.endDate }}</div>
          <div>For: {{ r.owner }}</div>
        </div>

        <div>
          <b-button
            type="is-primary"
            @click="confirmDeny(r)"
            rounded
            :style="{ marginLeft: '24px' }"
            outlined
            >Deny</b-button
          >

          <b-button
            type="is-primary"
            :style="{ marginLeft: '24px' }"
            @click="confirmApprove(r)"
            rounded
            outlined
            >Approve</b-button
          >
        </div>
      </div>
    </section>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    Modal
  },
  computed,
  data,
  methods,
  watch
}
</script>
