import moment from 'moment'
import { deleteAmenityReservation } from '@/services/Amenities/AmenityReservation/Delete'
import _get from 'lodash/get'
import { notifyMessage, notifyProblem } from '@/services/notify'
import { amenityStore } from '@/services/Amenities/store'
import { amenityReservationStore } from '@/services/Amenities/AmenityReservation/store'
import { reservationIsApproved } from '@/utilities/amenities/reservationIsApproved'

export const methods = {
  reservationIsApproved,
  open({ reservations, requests = false }) {
    this.isOpen = true
    this.showing = 1
    this.showingRequests = requests

    this.reservations = []
    this.reservations = reservations.map(r => {
      return {
        startDate: moment(r.startDate).format('LLLL'),
        endDate: moment(r.endDate).format('LLLL'),
        reservation: r
      }
    })
  },

  confirmCancel(reservation) {
    this.showing = 2
    this.cancellation = {
      reservation
    }

    if (
      this.cancellation.reservation.reservation.convenienceFeeAmount !== undefined &&
      this.cancellation.reservation.reservation.convenienceFeeAmount
    ) {
      this.convenienceFee = this.convenienceFeeFromPercent(
        this.cancellation.reservation.reservation.chargeAmount +
          this.cancellation.reservation.reservation.nonRefundableAmount,
        this.cancellation.reservation.reservation.convenienceFeeAmount
      )
    }
  },

  convenienceFeeFromPercent(amount, percent) {
    if (amount && percent) {
      try {
        const percentage = percent * 0.01
        const percentAmount = amount * percentage
        return percentAmount
      } catch (error) {
        console.debug('error in convenienceFeeFromPercent=' + error)
        return 0
      }
    }
  },

  async cancel() {
    this.loading = true

    const { reservation } = this.cancellation
    const amenityReservationID = _get(reservation, ['reservation', 'amenityReservationID'], null)

    if (typeof amenityReservationID !== 'number') {
      notifyProblem(
        `A problem occurred finding the reservation, and it could not be cancelled successfully`
      )
      this.loading = false
      return
    }

    const { successful, message } = await deleteAmenityReservation({
      amenityReservationID
    })

    if (!successful) {
      notifyProblem(message)
      this.loading = false
      return
    } else {
      if (this.reservationIsApproved({ reservation: reservation.reservation })) {
        const payload = {
          amenityReservationID
        }

        let chargeAmount = 0
        let nonRefundableAmount = 0
        let convenienceFeeAmount = 0

        if (
          this.cancellation.reservation.reservation !== undefined &&
          this.cancellation.reservation.reservation &&
          this.cancellation.reservation.reservation.chargeAmount
        ) {
          chargeAmount = this.cancellation.reservation.reservation.chargeAmount
        }

        if (
          this.cancellation.reservation.reservation.convenienceFeeAmount !== undefined &&
          this.cancellation.reservation.reservation.convenienceFeeAmount
        ) {
          convenienceFeeAmount = this.convenienceFeeFromPercent(
            this.cancellation.reservation.reservation.chargeAmount +
              this.cancellation.reservation.reservation.nonRefundableAmount,
            this.cancellation.reservation.reservation.convenienceFeeAmount
          )
        }

        if (
          this.cancellation.reservation.reservation.nonRefundableAmount !== undefined &&
          this.cancellation.reservation.reservation.nonRefundableAmount
        ) {
          nonRefundableAmount = this.cancellation.reservation.reservation.nonRefundableAmount

          if (
            this.cancellation.reservation.reservation.convenienceFeeAmount !== undefined &&
            this.cancellation.reservation.reservation.convenienceFeeAmount
          ) {
            nonRefundableAmount = nonRefundableAmount + convenienceFeeAmount || 0
          }
        }

        amenityReservationStore.dispatch('cancelWithRefund', {
          payload,
          done: detail => {
            if (detail) {
              console.debug('refunded cancellation= ' + JSON.stringify(detail))

              if (chargeAmount > 0) {
                var formatter = new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                })

                let notifymessage =
                  `This reservation was successfully cancelled with a refund of, $` +
                  formatter.format(chargeAmount) +
                  `. `
                if (nonRefundableAmount && nonRefundableAmount > 0) {
                  notifymessage =
                    notifymessage +
                    `There is a non-refundable amount for, $` +
                    formatter.format(nonRefundableAmount) +
                    `.`
                }

                notifyMessage(notifymessage)
              }
            } else {
              notifyMessage('This reservation has been successfully cancelled with a refund.')
            }
          }
        })
      } else {
        notifyMessage('This reservation has been successfully cancelled.')
      }
    }

    await amenityStore.commit('updateMain')

    this.loading = false
    this.isOpen = false
  }
}
